import React, {useState} from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
// Import Shared Components
import { ActionBar, ButtonGroup, InputGroup, SubmitButton, Checkbox, Link, Title} from 'shared-components';
// Import Shared Hooks
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Component Styles
import {
  Form,
  RedText,
  FormGroup,
  InputContainer,
  StyledModal,
  StyledAlert,
  StyledButton
} from '../styles';
// Import Translations
import { verifyEn } from '../../../i18n/verify';
import TermsLink from './TermsLink';

export default injectIntl(
  ({ history, intl, virtualCardRedemptionDetailId, error, setError, setVerifyingCard }) => {
    const initialFormState = {
      id: virtualCardRedemptionDetailId,
      firstName: '',
      lastName: '',
      email: '',
      accessCode: '',
      esign: false,
      agreementVersion: '1.0',
      tc: false,
    };
    const [inputInfo, updateInputInfo] = useUpdateStateObject(initialFormState);
    const [showModal, setShowModal] = useState(false);

    const handleOnChange = (key, value) => {
      if (error !== null) setError(null);
      return updateInputInfo({ key, value });
    };

    const handleOnSubmit = async e => {
      e.preventDefault();

      const redemptionPayload = {
        id: virtualCardRedemptionDetailId,
        first_name: inputInfo.firstName,
        last_name: inputInfo.lastName,
        email: inputInfo.email,
        access_code: inputInfo.accessCode,
        agreement_version: inputInfo.agreementVersion,
      };

      try {
        setVerifyingCard(true);
        const res = await fetch('/api/v1/virtual_card/activate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(redemptionPayload),
        });

        const data = await res.json();

        // If an error occured when activating the card, we can display an error message
        if (data && data.error) {
          setVerifyingCard(false);
          throw new Error();
        }

        // Details have been verified and Card has been activated
        setVerifyingCard(false);
        history.push('/activated', { virtualCardDetails: data.data });
      } catch (e) {
        setVerifyingCard(false);
        setError([
          <>
            There seems to be a problem. The information provided is incorrect
            in at least one of the fields.
          </>,
          <strong>
            Please reach out to your card provider for assistance, contact
            details provided in the redemption email.
          </strong>,
        ]);
      }
    };

    const handleOnActivateClicked = async e => {
      e.preventDefault();

      setShowModal(true);
    };
  
    const toggleModal = () => {
      setShowModal(!showModal);
    };

    const disabled = !(inputInfo.esign && inputInfo.tc);

    const renderForm = () => {
      return(      
        <Form id="verify" onSubmit={disabled ? () => {} : handleOnActivateClicked}>
          <FormGroup form="verify">
            <InputContainer>
              <InputGroup
                dataId="firstName"
                label={(
                  <FormattedMessage
                    id="verify-firstName-label"
                    description={`Label for ${verifyEn['verify-firstName-label']}`}
                    defaultMessage={verifyEn['verify-firstName-label']}
                  />
                )}
                labelFor="firstName"
                onChange={e => handleOnChange('firstName', e.target.value)}
                required
                value={inputInfo.firstName}
              />
              <InputGroup
                dataId="lastName"
                label={(
                  <FormattedMessage
                    id="verify-lastName-label"
                    description={`Label for ${verifyEn['verify-firstName-label']}`}
                    defaultMessage={verifyEn['verify-firstName-label']}
                  />
                )}
                labelFor="lastName"
                onChange={e => handleOnChange('lastName', e.target.value)}
                required
                value={inputInfo.lastName}
              />
            </InputContainer>
            <InputContainer>
              <InputGroup
                dataId="email"
                label={(
                  <FormattedMessage
                    id="verify-email-label"
                    description={`Label for ${verifyEn['verify-email-label']}`}
                    defaultMessage={verifyEn['verify-email-label']}
                  />
                )}
                labelFor="email"
                type="email"
                onChange={e => handleOnChange('email', e.target.value)}
                required
                value={inputInfo.email}
              />
              <InputGroup
                dataId="accessCode"
                label={(
                  <FormattedMessage
                    id="verify-accessCode-label"
                    description={`Label for ${verifyEn['verify-accessCode-label']}`}
                    defaultMessage={verifyEn['verify-accessCode-label']}
                  />
                )}
                labelFor="accessCode"
                onChange={e => handleOnChange('accessCode', e.target.value)}
                required
                value={inputInfo.accessCode}
              />
            </InputContainer>
            <RedText>
              <FormattedMessage
                id="verify-warning-text"
                description="Text for T&C and E-sign form warning"
                defaultMessage={verifyEn['verify-warning-text']}
              />
            </RedText>
            <Checkbox
              label={(
                <FormattedMessage
                  id="verify-esign-label"
                  description="Label for esign"
                  defaultMessage={verifyEn['verify-esign-label']}
                  values={{
                    link: (
                      <Link
                        to="/esign"
                        type="primary"
                        target="_blank"
                      >
                        <FormattedMessage
                          id="verify-esign-link"
                          description="Link for esign"
                          defaultMessage={verifyEn['verify-esign-link']}
                        />
                      </Link>
                    ),
                  }}
                />
              )}
              onChange={e => handleOnChange('esign', e.target.checked)}
            />
            <Checkbox
              label={(
                <FormattedMessage
                  id="verify-tc-label"
                  description="Label for tc"
                  defaultMessage={verifyEn['verify-tc-label']}
                  values={{
                    link: (
                      <TermsLink />
                    ),
                  }}
                />
              )}
              onChange={e => handleOnChange('tc', e.target.checked)}
            />
          </FormGroup>
          <SubmitButton
            buttonType="primary"
            size="large"
            value={intl.formatMessage({
              id: 'verify-submit-button',
              defaultMessage: verifyEn['verify-submit-button'],
            })}
            disabled={disabled}
          />    
        </Form>
      );
    };

    const renderBody = () => {
      if (!showModal) return (renderForm());
      if (showModal) return ( 
        <>
          {renderForm()}
          <StyledModal key="activate-card-modal">
            <Title>Card Activation</Title>
            <StyledAlert type="warn">
              {intl.formatMessage({
                id: 'verify-activate-modal-text',
                defaultMessage: verifyEn['verify-activate-modal-text'],
              })}
              <br /><br />
              {intl.formatMessage({
                id: 'verify-activate-modal-proceed-text',
                defaultMessage: verifyEn['verify-activate-modal-proceed-text'],
              })}
            </StyledAlert>
            <ActionBar>
              <ButtonGroup reverse justifyContent="center">
                <StyledButton type="primary" size="large" onClick={handleOnSubmit}>
                  {intl.formatMessage({
                    id: 'verify-activate-modal-proceed-button',
                    defaultMessage: verifyEn['verify-activate-modal-proceed-button'],
                  })}
                </StyledButton>
                <StyledButton onClick={toggleModal} size="large">
                  {intl.formatMessage({
                    id: 'verify-activate-modal-cancel-button',
                    defaultMessage: verifyEn['verify-activate-modal-cancel-button'],
                  })}                
                </StyledButton>
              </ButtonGroup>
            </ActionBar>
          </StyledModal>
        </>
      );
      return null;
    };
  
    return <div>{renderBody()}</div>;
  }
);

