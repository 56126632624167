import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Loader, ErrorComponent } from 'shared-components';
import useTellerApi from 'shared-components/src/hooks/useTellerApi';
// Import Global Components
import Main from '../../components/Main';
// Import Layouts
import MainLayout from '../../layouts/Main';
// Import Local Components
import Form from './components/Form';
import SectionOne from './components/SectionOne';
import InvalidLink from './components/InvalidLink';
// Import Translations
import { verifyEn } from '../../i18n/verify';

const Verify = ({ history, match: { params }, ...props }) => {
  const [verifyingCard, setVerifyingCard] = useState(false);
  const [error, setError] = useState(null);
  const [
    virtualCardRedemptionDetailId,
    setVirtualCardRedemptionDetailId
  ] = useState(null);

  const {emailLink} = params;
  const [{ data, isLoading, isError }] = useTellerApi(
    `/api/v1/virtual_card/validate?link=${emailLink}`,
    null
  );

  if (isLoading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (verifyingCard) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (isError) {
    return (
      <MainLayout>
        <ErrorComponent />
      </MainLayout>
    );
  }

  // Request to Validate Email Link has completed Successfully

  // If the response contains an error then we can display the correct message to the user
  if (data && data.error) {
    // The Link in the UrL could not be verified
    return <InvalidLink />;
  } if (data && data.data && !virtualCardRedemptionDetailId) {
    // Link in Email has been verified to we can set the redemption ID
    // in the state if it has not yet been set
    setVirtualCardRedemptionDetailId(data.data.id);
  }

  return (
    <Main
      title={verifyEn['verify-main-title']}
      subTitle={verifyEn['verify-main-text']}
      page="verify"
      sectionOne={<SectionOne />}
      sectionTwo={(
        <Form
          virtualCardRedemptionDetailId={virtualCardRedemptionDetailId}
          history={history}
          error={error}
          setError={setError}
          setVerifyingCard={setVerifyingCard}
        />
      )}
      error={error}
      {...props}
    />
  );
};

Verify.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  emailLink: PropTypes.string.isRequired
};


export default Verify;