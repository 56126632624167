import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { Link, ExternalLink } from 'shared-components';
import { verifyEn } from '../../../i18n/verify';


export default withTheme(({ theme }) => {
  if (theme.brand.virtualCardTerms) {
    return (
      <ExternalLink
        href={theme.brand.virtualCardTerms}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage
          id="verify-tc-link"
          description="Link for tc"
          defaultMessage={verifyEn['verify-tc-link']}
        />
      </ExternalLink>
    );
  }
  return (
    <Link
      to="/terms"
      type="primary"
      target="_blank"
    >
      <FormattedMessage
        id="verify-tc-link"
        description="Link for tc"
        defaultMessage={verifyEn['verify-tc-link']}
      />
    </Link>
  );
});
