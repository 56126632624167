export const verifyEn = {
  'verify-main-title': 'Your Virtual Prepaid Card is Almost Ready!',
  'verify-main-text':
    'To redeem and activate your Virtual Prepaid Card, please complete the mandatory fields.',
  'verify-sectionOne-label': 'What is a Virtual Prepaid Card?',
  'verify-sectionOne-p1-text':
    'Virtual Prepaid Cards are similar to plastic prepaids, except without the plastic! You can use your Virtual Prepaid Card anywhere Visa/Mastercard is accepted.',
  'verify-sectionOne-p2-text':
    'The card must be activated in order to be used for purchases. Once activated, you may register and optionally update the name and address associated with the card.',
  'verify-form-error-alert':
    'There seems to be a problem. The information provided is incorrect in at least one of the fields. Please reach out to your card provider for assistance, contact details provided in the redemption email.',
  'verify-firstName-label': 'First Name',
  'verify-lastName-label': 'Last Name',
  'verify-email-label': 'Email',
  'verify-accessCode-label': 'Access Code',
  'verify-warning-text': 'Terms and E-Sign form must be clicked to proceed',
  'verify-esign-label':
    'By checking this box I have read and agree to the terms of the {link}, which must be opened and viewed to proceed.',
  'verify-esign-link': 'E-sign Form (link)',
  'verify-tc-link': 'Terms and Conditions (link)',
  'verify-tc-label': 'I have read and agree to the {link}.',
  'verify-submit-button': 'Activate Card',
  'verify-invalidLink-title': 'Unable to Locate Virtual Card...',
  'verify-invalidLink-text':
    'We are sorry we are unable to locate your Virtual Card, please contact the sender of your Virtual Card.',
  'verify-activate-modal-text': 
  'The next page will only be displayed once, so be sure to print or save the card number, CVV, and expiration date before exiting the page.',
  'verify-activate-modal-proceed-text': 'Are you sure you want to activate your card now?',
  'verify-activate-modal-cancel-button': 'No',
  'verify-activate-modal-proceed-button': 'Yes'
};

export const verifyFr = {
  'verify-main-title': 'Votre carte prépayée virtuelle est presque prête!',
  'verify-main-text':
    'Pour réclamer et pour activer votre carte prépayée virtuelle, veuillez remplir les informations suivantes.',
  'verify-sectionOne-label': 'Qu\'est-ce qu\'une carte virtuelle?',
  'verify-sectionOne-p1-text':
    'Les cartes prépayées virtuelles sont similaires aux cartes prépayées en plastique! Vous pouvez utiliser votre carte prépayée virtuelle partout où Visa est acceptée.',
  'verify-sectionOne-p2-text':
    'La carte doit être activée, puis vous pouvez l\'utiliser pour vos achats. Lorsque la carte a été activée, vous pouvez vous inscrire et vous pouvez changer le nom et l\'adresse si vous voulez.',
  'verify-form-error-alert':
    'Il semble y avoir un problème. Les informations fournies sont incorrectes dans au moins un des champs. Veuillez contacter votre fournisseur de carte pour obtenir de l’aide. Vous trouverez ses coordonnées dans le courriel de récupération.',
  'verify-firstName-label': 'Prénom',
  'verify-lastName-label': 'Nom de famille',
  'verify-email-label': 'Email',
  'verify-accessCode-label': 'Code d\'accès',
  'verify-warning-text':
    'Vous devez cliquer sur les termes et conditions et sur le formulaire E-signe pour continuer.',
  'verify-esign-label':
    'J’ai lu et accepté ici les termes du {link}, ce qui doit être ouvert et regardé avant de continuer.',
  'verify-esign-link': 'formulaire E-Sign (lien)',
  'verify-tc-link': 'termes et conditions (lien)',
  'verify-tc-label': 'J\'ai lu et j\'accepte les {link}.',
  'verify-submit-button': 'Activer la carte',
  'verify-invalidLink-title': 'Impossible de localiser la carte virtuelle...',
  'verify-invalidLink-text':
    'Nous sommes désolés de ne pas être en mesure de localiser votre carte virtuelle, veuillez contacter l\'expéditeur de votre carte virtuelle.',
  'verify-activate-modal-text': 'La page suivante ne sera affichée qu\'une fois, assurez-vous donc d\'imprimer ou sauvegarder le numéro de carte, le CVV et la date d\'expiration avant de quitter la page.',
  'verify-activate-modal-proceed-text': 'Êtes-vous sûr de vouloir activer votre carte maintenant?',
  'verify-activate-modal-cancel-button': 'Non',
  'verify-activate-modal-proceed-button': 'Oui'

};
